class Section {
  constructor(element) {
    this.element = element;
    this.id = element.dataset.id;
    this.uri = element.dataset.uri;
    element.__section = this;

    this.setDimensions();
    window.addEventListener('load', () => {
      this.setDimensions();
    });
    window.addEventListener('resize', () => {
      this.setDimensions();
    });
  }

  setDimensions() {
    const { element } = this;
    this.dimensions = {
      top: element.offsetTop,
      height: element.offsetHeight,
    };
  }

  get isInView() {
    const { dimensions } = this;
    if (dimensions) {
      let { scrollTop } = document.scrollingElement;
      scrollTop += 50; // pixel before it’s actually in view.
      return scrollTop >= dimensions.top && scrollTop < dimensions.top + dimensions.height;
    }
    return false;
  }
}


export default Section;
