// import '@babel/polyfill';
import 'airbnb-browser-shims/browser-only';
import 'prismjs';
import './polyfills/scrollingElement';
import ClipboardJS from 'clipboard';
import Header from './components/Header';
import Section from './components/Section';
import FormLogin from './components/FormLogin';

function detectIE() {
  const { userAgent } = window.navigator;
  if (userAgent.includes('MSIE ') || userAgent.includes('Trident/')) {
    return true;
  }
  return false;
}

if (detectIE()) {
  document.querySelector('body').classList.add('is-ie');
}

const clipboard = new ClipboardJS('.button');
clipboard.on('success', (event) => {
  const triggerElement = event.trigger;
  triggerElement.dataset.copyText = triggerElement.textContent;
  triggerElement.textContent = triggerElement.dataset.successText;
  event.clearSelection();
  triggerElement.focus();
  setTimeout(() => {
    triggerElement.blur();
  });
  setTimeout(() => {
    triggerElement.textContent = triggerElement.dataset.copyText;
  }, 1000 * 5);
});


if (document.querySelector('.header')) {
  document.header = new Header(document.querySelector('.header'));
}

if (document.querySelector('.form-login')) {
  document.formLogin = new FormLogin(document.querySelector('.form-login'));
}


const mainnavAElements = [...document.querySelectorAll('.mainnav a')].filter(element => element.href.includes('#'));
const sectionElements = [...document.querySelectorAll('.section')];
document.sections = [];

[...sectionElements].forEach((sectionElement) => {
  document.sections.push(new Section(sectionElement));
});

function scrollToSection() {
  const hash = window.location.hash.substr(1);
  const sectionToBecomeVisible = document.sections.find(section => section.id === hash);
  if (sectionToBecomeVisible) {
    if (document.header) {
      document.header.close();
    }
    sectionToBecomeVisible.element.scrollIntoView({
      behavior: 'smooth',
    });
  }
}

function updateMainnavAElements() {
  const activeSection = document.sections.find(section => section.isInView);
  mainnavAElements.forEach((mainnavAElement) => {
    if (activeSection && mainnavAElement.dataset.uri === activeSection.uri) {
      mainnavAElement.classList.add('is-active');
    } else {
      mainnavAElement.classList.remove('is-active');
    }
  });
}


mainnavAElements.forEach((mainnavAElement) => {
  mainnavAElement.addEventListener('click', () => {
    if (mainnavAElement.href === window.location.href) {
      scrollToSection();
    }
  });
});

window.addEventListener('hashchange', () => {
  scrollToSection();
});

window.addEventListener('scroll', () => {
  updateMainnavAElements();
}, { passive: true });

window.addEventListener('load', () => {
  updateMainnavAElements();
  if (window.location.hash.length > 0) {
    scrollToSection();
  }
});
window.addEventListener('resize', () => {
  updateMainnavAElements();
});
updateMainnavAElements();


if ('ontouchstart' in document.documentElement) {
  document.body.classList.add('is-touch');
}
