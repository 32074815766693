class Header {
  constructor(element) {
    const hamburgerElement = document.querySelector('.hamburger');
    const headerOverlayElement = document.querySelector('.header-overlay');
    const mainnavElement = element.querySelector('.mainnav');

    this.element = element;
    this.mainnavElement = mainnavElement;

    this.onTransitionEnd = () => {
      if (!element.classList.contains('is-open') && window.matchMedia('(max-width: 1111px)').matches) {
        mainnavElement.hidden = true;
      }
      element.removeEventListener('transitionend', this.onTransitionEnd);
    };

    function updateMainnavHidden() {
      if (!element.classList.contains('is-open') && window.matchMedia('(max-width: 1111px)').matches) {
        mainnavElement.hidden = true;
      } else {
        mainnavElement.hidden = false;
      }
    }

    hamburgerElement.addEventListener('click', () => {
      if (!element.classList.contains('is-open')) {
        this.open();
      } else {
        this.close();
      }
    });

    headerOverlayElement.addEventListener('click', () => {
      this.close();
    });
    window.addEventListener('resize', () => {
      updateMainnavHidden();
    }, {
      passive: true,
    });

    updateMainnavHidden();
  }

  open() {
    const { element, mainnavElement } = this;
    mainnavElement.hidden = false;
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        document.body.classList.add('is-header-open');
        element.classList.add('is-open');
      });
    });
  }

  close() {
    if (document.body.classList.contains('is-header-open')) {
      const { element } = this;
      document.body.classList.remove('is-header-open');
      element.classList.remove('is-open');
      element.removeEventListener('transitionend', this.onTransitionEnd);
      element.addEventListener('transitionend', this.onTransitionEnd);
    }
  }
}

export default Header;
