class FormLogin {
  constructor(element) {
    this.element = element;
    const errorElement = element.querySelector('.error');

    function showLoading() {
      element.classList.add('is-loading');
    }
    function hideLoading() {
      element.classList.remove('is-loading');
    }

    function removeError() {
      errorElement.textContent = '';
    }

    function showError(message) {
      console.warn(message);
      errorElement.textContent = message;
    }

    function checkPassword() {
      fetch(element.action, {
        method: element.method,
        credentials: 'same-origin',
        body: new FormData(element),
      }).then((response) => {
        hideLoading();
        if (response.status === 200) {
          window.location = `${window.location.origin}${window.location.pathname}`;
          return '';
        }
        return response.text();
      }).then((text) => {
        if (text.length > 0) {
          showError(text);
        }
      }).catch(() => {
        showError('Unbekannter Fehler');
      });
    }

    element.addEventListener('submit', (event) => {
      event.preventDefault();
      removeError();
      showLoading();
      checkPassword();
    });
  }
}

export default FormLogin;
